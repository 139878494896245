// Email
// export const pointOfContactEmail = "aaron.sisler@eandbsolutions.com";
// export const pointOfContactEmail = "jdwireart@aol.com";
export const pointOfContactEmail = "valleylovehs@gmail.com";
export const websiteURL = "https://jdwireartstudio.com";

//Paypal
// export const paypalEnvironment = "sandbox";
export const paypalEnvironment = "production";

// Services Gateway
export const servicesApiGatewayURL = "https://services.eandbsolutions.com/prod";
