const categories = [
  {
    key: "ALL",
    value: "All"
  },
  {
    key: "AIRCRAFT",
    value: "Aircraft"
  },
  {
    key: "AUTOS",
    value: "Automobiles"
  },
  {
    key: "BUGS",
    value: "Bugs"
  },
  {
    key: "MOTORCYCLES",
    value: "Motorcycles"
  },
  {
    key: "REPTILES",
    value: "Reptiles"
  },
  {
    key: "OTHER",
    value: "Other"
  }
];

export default categories;
