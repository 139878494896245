//Firebase Config
// export const firebaseConfig = {
//   apiKey: "AIzaSyCPm69jSJozqcNLz2DpOOni1Aq0YlZXi-4",
//   authDomain: "beta-eandb-project-release.firebaseapp.com",
//   databaseURL: "https://beta-eandb-project-release.firebaseio.com",
//   projectId: "beta-eandb-project-release",
//   storageBucket: "beta-eandb-project-release.appspot.com",
//   messagingSenderId: "131533228714",
//   appId: "1:131533228714:web:a77ab24c9b7d0a95"
// };

export const firebaseConfig = {
  apiKey: "AIzaSyDfStlnvYEBNlUCiqkTeiel5wwhZv23aZk",
  authDomain: "jdwireart.firebaseapp.com",
  databaseURL: "https://jdwireart.firebaseio.com",
  projectId: "jdwireart",
  storageBucket: "jdwireart.appspot.com",
  messagingSenderId: "87377359928"
};

// Paypal API Keys
// E&B Paypal
// export const paypalProductionClientId =
//   "AQX1uPkZgzd5ZWe3-mihza8EiGfJAGUDxPiBPnqHLy0iY8W4uIWGBSKR4ssVuXrW9KbRVnR3_3oNnf4r";
// export const paypalSandboxClientId =
//   "AXItpK63JSFjy_PXAWvgrg7zpjexyvqhJbbyHq8yBliyR551HcfRGgWIjOuYH3tQJ9HzgaVHx5RtY91Y";
// export const paymentVerificationCode = "a";

// JD WireArt Paypal
export const paypalProductionClientId =
  "Aaa_1hRxeAClBN4ZJXFLVFB6BZV82RX66xE5-_IK1KmXxZ1yNhsITEZvp9ybhcCY_UbVqOC13kd67q36";
export const paypalSandboxClientId =
  "AXItpK63JSFjy_PXAWvgrg7zpjexyvqhJbbyHq8yBliyR551HcfRGgWIjOuYH3tQJ9HzgaVHx5RtY91Y";
export const paymentVerificationCode = "sharezim";

//Services Gateway Config
// Beta
// export const servicesApiGatewayToken = "3Zt2rewSQh2FJCLkZeQPC42glRNBnvpZ5ef4aKlC";

// Prod
export const servicesApiGatewayToken =
  "HdOvIATiEU28qyR3glui06brJdR1BCG55otFffqX";
